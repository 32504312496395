function reset_animation() {
  var el = document.body;
  el.style.animation = 'none';
  el.offsetHeight; /* trigger reflow */
  el.style.animation = null;
}


window.setInterval(function(){
   reset_animation();
}, 30000);
